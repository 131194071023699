<template>
  <div class="flex flex-col items-center gap-4">
    <slot></slot>
    <div class="flex justify-center items-center text-primary-60">

      <div style="border-top-color:transparent"
        class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>
