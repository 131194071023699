<template>
  <div class="flex-1 flex flex-col">
    <ul class="overflow-hidden overflow-y-auto">
      <li
        v-for="chat in sortedChats"
        :key="chat.id"
        class="border-b border-gray-cloud border-opacity-50 last:border-b-0"
      >
        <router-link
          :to="{ name: 'ProjectInteractions', params: { chatId: chat.id } }"
          class="flex flex-row items-center px-3 py-2 bg-black hover:bg-opacity-10"
          :class="
            chat.id == $route.params.chatId ? 'bg-opacity-10' : 'bg-opacity-0'
          "
        >
          <div class="mr-3">
            <Icon
              v-show="chatIsBookmarked(chat)"
              name="star"
              size="lg"
              class="z-1 absolute top-0 left-0 -ml-1 text-warning"
            />
            <Avatar
              :name="company(chat.pro_id)"
              :imageUrl="avatarUrl(chat.pro_id)"
            />
          </div>
          <div class="flex-1">
            <div
              class=" flex flex-col text-left justify-between items-start font-sans"
            >
              <div class="text-sm font-bold text-gray-smoke">
                {{ company(chat.pro_id) }}
              </div>
              <div class="text-2xs text-gray-smoke break-all">
                {{ email(chat.pro_id) }}
              </div>
              <div class="text-2xs text-gray-elephant">
                {{ $date(chat.updated_at).fromNow() }}
              </div>
            </div>
          </div>
          <div>
            <Dot
              v-if="hasNewInteractions(chat.id)"
              type="danger"
              class="mx-2"
            />
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "List",

  props: {
    chats: {
      type: Array,
      defaut: () => []
    },

    pros: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters("chat", ["sortedChats"]),
    ...mapGetters("interaction", ["newInteractionArray"])
  },

  methods: {
    chatIsBookmarked(chat) {
      return chat.bookmarked;
    },

    company(proId) {
      let pro = _.find(this.pros, { id: proId });
      return !_.isNil(pro) ? pro.company : "";
    },

    avatarUrl(proId) {
      let pro = _.find(this.pros, { id: proId });
      return !_.isNil(pro) && !_.isNil(pro.avatar_url)
        ? process.env.VUE_APP_CMN_URL + pro.avatar_url
        : "";
    },

    email(proId) {
      let pro = _.find(this.pros, { id: proId });
      return !_.isNil(pro) ? pro.email : "";
    },

    hasNewInteractions(chatId) {
      return _.filter(this.newInteractionArray, { chat_id: chatId }).length > 0;
    }
  }
};
</script>
