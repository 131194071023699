import _ from "lodash";

const state = () => ({
  proArray: [],
  selectedPro: null
});

const getters = {
  findById(state) {
    return function(idPro) {
      return _.find(state.proArray, { id: idPro });
    };
  }
};

const actions = {};

const mutations = {
  INIT_LIST(state, pros) {
    state.proArray = pros;
  },

  SET_SELECTED(state, id) {
    state.selectedPro = _.find(state.proArray, { id: id });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
