<template>
  <ul v-if="breadcrumb" class="flex flex-row font-sans text-sm">
    <li v-for="(crumb, index) in breadcrumb" :key="crumb.path">
      <template v-if="index < breadcrumb.length - 1">
        <router-link
          :to="crumb.to"
          class="text-gray-elephant text-opacity-50 hover:text-opacity-75"
        >
          {{ crumb.text }}
        </router-link>
        <span class="mx-2 text-gray-elephant text-opacity-50">&sol;</span>
      </template>
      <span v-else class="text-gray-elephant">{{ crumb.text }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    breadcrumb: {
      type: Array
    }
  }
};
</script>
