import Vue from "vue";
import App from "./App.vue";
import _ from "lodash";

import "@ONELABCMN/design-system/dist/cherchemonnid-design-system.css";
import "@/assets/css/styles.css";
import {
  AvatarPlugin,
  BadgePlugin,
  ButtonPlugin,
  DividerPlugin,
  DotPlugin,
  IconPlugin,
  InteractionBadgePlugin,
  ModalPlugin,
  PanelPlugin,
  SelectPlugin,
  SnackbarPlugin,
  TagPlugin,
  TogglePlugin,
  RadioButtonPlugin,
  CheckboxPlugin
} from "@ONELABCMN/design-system";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import router from "./router";
import store from "./store";
import i18n from "./locales";
import config from "./config";

import VueGtm from "@gtm-support/vue2-gtm";
import GtmApi from "@/services/api/Gtm";
import messaging from "./firebase";

Vue.config.productionTip = false;

Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(DividerPlugin);
Vue.use(DotPlugin);
Vue.use(IconPlugin);
Vue.use(InteractionBadgePlugin);
Vue.use(ModalPlugin);
Vue.use(PanelPlugin);
Vue.use(SelectPlugin);
Vue.use(SnackbarPlugin);
Vue.use(TagPlugin);
Vue.use(TogglePlugin);
Vue.use(RadioButtonPlugin);
Vue.use(CheckboxPlugin);

Vue.use(config);

Vue.use(VueGtm, {
  id: "GTM-K7T5P5F",
  queryParams:
    process.env.NODE_ENV === "production"
      ? {}
      : {
        gtm_auth: "sTy7fIvyaEdxNzcvlKV78A",
        gtm_preview: "env-27",
        gtm_cookies_win: "x"
      }
});

dayjs.locale("fr");
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    }
  },
  $messaging: {
    get() {
      return messaging;
    }
  }
});

Vue.filter("capitalize", function (str) {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1);
});

Vue.filter("uppercase", function (str) {
  if (!str) return "";

  return str.toUpperCase();
});

Vue.config.productionTip = false;

window.addEventListener("flutterInAppWebViewPlatformReady", function () {
  store.dispatch("flutter/flutterReady");
});

window.addEventListener("fcmTokenRefresh", function (event) {
  store.dispatch("user/updateFcmToken", event.detail);
});

window.addEventListener("newChatMessage", function (event) {
  store.dispatch("project/fetchChats", { id: event.detail.project_id });
  store.dispatch("chat/fetchMessages", { id: event.detail.chat_id });
});

async function checkAuth() {
  try {
    if (messaging !== null) {
      //firebase registration token
      messaging
        .getToken({ vapidKey: process.env.VUE_APP_VAPID_KEY })
        .then(currentToken => {
          if (currentToken) {
            store.dispatch("user/updateFcmToken", {
              device: 1, // WEB
              new_token: currentToken
            });
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
        });

      messaging.onMessage(function (payload) {
        console.log("Foreground Message received. ", payload);
        // ...
      });
    }

    const user = await store.dispatch("user/fetchMe");
    window.dataLayer.push({ user_id: user.id });

    await store.dispatch("user/fetchProject", {
      id: user.id
    });

    await Promise.all([
      store.dispatch("project/fetchSearches", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchInteractions", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchChats", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchPros", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchSale", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchProgresses", {
        id: store.state.project.selectedProject.id
      }),
      store.dispatch("project/fetchAds", { forcePolling: false }),
      store.dispatch("user/fetchDocuments", {
        id: user.id
      })
    ]);

    store.commit("project/NEED_PROJECT_DATA_REFRESH", false);

    window.dataLayer.push({ user_profile: store.getters["user/gtmProfile"] });

    const response = await GtmApi.get();
    _.forEach(response, function (gtm) {
      Vue.gtm.trackEvent({
        ...gtm.payload,
        noninteraction: false
      });
    });
  } catch (error) {
    console.log(error);
  }
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#app");
}

checkAuth().then(createApp);

