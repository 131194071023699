<template>
    <article class="flex flex-col">
        <div class="flex flex-col md:flex-row gap-4 justify-center md:justify-start items-center">
            <Tag size="l">
                {{ selectedAd ? $t('pages.ads.category.' + selectedAd.ad_data.category) : '' }} </Tag>

            <PriceChanged class="text-xs" />


            <h1 class="text-xl md:text-left md:truncate">
                {{ selectedAd ? selectedAd.ad_data.title : '' }}
            </h1>
        </div>
        <div class="flex flex-col md:flex-row gap-2">
            <!--Spec-->
            <div class="flex flex-col md:w-1/2 p-5 font-lato shadow-md rounded-sm">
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.price') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ formattedPrice(selectedAd.ad_data.price) }}
                    </p>
                </div>

                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.area') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ $t('property_values.area', { "area": selectedAd.ad_data.surface }) }}
                    </p>
                </div>

                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $tc('property_labels.room_count', selectedAd.ad_data.rooms) }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.rooms }}
                    </p>
                </div>
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $tc('property_labels.bedroom_count', selectedAd.ad_data.bedrooms) }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.bedrooms }}
                    </p>
                </div>

                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('pages.ads.city') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.location.city }}
                    </p>
                </div>

                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('pages.ads.zip') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.location.postalCode }}
                    </p>
                </div>
                <div class="flex flex-wrap p-2 gap-1">
                    <Tag size="l" class="bg-primary-100" v-for="option in getOptions" :key="option">
                        {{ $t('pages.ads.options.' + option) }}
                    </Tag>
                </div>
                <div class="flex-1"></div>
                <Button class="mt-4" @click="contact(selectedAd.id)">{{ $t('pages.ads.visit') }}</Button>
            </div>
            <div class="flex flex-col md:w-1/2 p-5 font-lato shadow-md rounded-sm">
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.land_surface') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ $t('property_values.area', { "area": selectedAd.ad_data.landSurface }) }}
                    </p>
                </div>
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.building_floors') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.buildingFloors }}
                    </p>
                </div>
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.construction_year') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.constructionYear }}
                    </p>
                </div>
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.energy_grade') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.energyGrade }}
                    </p>
                </div>
                <div class="flex border-b-1/2">
                    <p class="flex-1 p-2">
                        {{ $t('property_labels.gas_grade') }}
                    </p>
                    <p class="flex-1 p-2">
                        {{ selectedAd.ad_data.energyGas }}
                    </p>
                </div>
            </div>
            <div class="md:flex-1"></div>
            <AdActions :ad="selectedAd" appearance="big" @on-like="like" @on-dislike="dislike" @on-contact="contact">
            </AdActions>            
        </div>
        <div class="flex flex-col p-5 shadow-md rounded-sm text-justify text-sm md:text-base">
            {{ selectedAd.ad_data.description }}
        </div>
        <!--Pictures-->
        <div>
            <div class="flex flex-wrap shadow-md">
                <div class="flex flex-col md:w-1/3 p-2" v-for="picture, index in selectedAd.ad_data.pictureUrls"
                    :key="index">
                    <img :src="picture" alt="ad picture" class="w-full h-full object-cover bg-no-image" />
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AdMixin from '@/components/Ad/AdMixin';
import _ from 'lodash';
import AdActions from '@/components/Ad/AdActions.vue';
import PriceChanged from '@/components/Ad/PriceChanged.vue';

export default {
    name: 'SingleAd',
    mixins: [AdMixin],
    components: {
        AdActions,
        PriceChanged,
    },
    computed: {
        ...mapState('ad', ['selectedAd']),
        ad() {
            return this.selectedAd;
        },
        getOptions() {
            return _.intersection(this.selectedAd.ad_data.options, this.availableOptions);
        }
    },
    methods: {
        ...mapActions('ad', ['targetAd']),
    },
    mounted() {
        this.targetAd(this.$route.params.adId);
    },

}
</script>
