<template>
    <article class="relative rounded-sm p-2 w-56 xl:w-64 shadow-md">
        <div class="flex font-lato mb-1 items-center">
            <Tag>{{ $t('pages.ads.category.' + ad.ad_data.category) }}</Tag>
            <div class="flex-1"></div>
            <span class="text-2xs italic">{{ formattedDate(ad.published_at) }}</span>
        </div>
        <router-link :to="{
            name: 'AdConsult',
            params: { adId: ad.id }
        }">
            <img :src="ad.ad_data.pictureUrls[0]" class="w-full h-32 object-cover rounded-sm cursor-pointer mb-3">
        </router-link>
        <div class="flex flex-col gap-2 items-center">
            <span class="text-sm font-semibold">{{ ad.ad_data.location.city }} - {{ ad.ad_data.location.postalCode }}</span>
            <div class="flex gap-2">
                <Tag class="bg-primary-50" size="l">{{ ad.ad_data.surface }} m2</Tag>
                <Tag class="bg-sunset-200" size="l">{{ formattedPrice(ad.ad_data.price) }}</Tag>
                <PriceChanged :labelled="false" v-if="ad.ad_data.options.includes('hasPriceChanged')" />
            </div>
            <AdActions :ad="ad" @on-like="like" @on-dislike="dislike" @on-contact="contact"></AdActions>
        </div>

        <div class="absolute top-0 left-0 w-full h-full bg-white opacity-50 rounded-sm cursor-not-allowed"
            v-if="!ad.active"></div>
        <div v-if="!ad.active" class="filigrane absolute top-1/2 left-1/2 text-4xl md:text-5xl text-pink-light">{{
            $t('pages.ads.disabled') |
            uppercase
        }}</div>
    </article>
</template>

<script>

import AdMixin from "./AdMixin";
import AdActions from "./AdActions.vue";
import PriceChanged from "./PriceChanged.vue";

export default {
    name: "AdMini",
    components: {
        AdActions,
        PriceChanged
    },
    mixins: [AdMixin],
    props: {
        ad: {
            type: Object,
            required: true
        }
    },

}
</script>

<style scoped>
.filigrane {
    transform: translate(-50%, -50%) rotate(-15deg);
}
</style>