<template>
  <div
    class="flex items-end"
    :class="message.user_to_pro ? 'flex-row-reverse' : 'flex-row'"
  >
    <div v-if="!isAuto" :class="message.user_to_pro ? 'ml-2' : 'mr-2'">
      <Avatar
        v-bind="avatarProps"
        :title="
          message.user_to_pro ? $t('pages.interactions.chat.you') : pro.company
        "
      />
    </div>
    <div v-if="isAuto" class="w-9 h-9 mb-1 ml-1 mr-1">
      <ErnestIsProud />
    </div>
    <div class="flex flex-col" style="max-width: 70%">
      <div
        class="px-5 py-3 rounded-xxl font-sans text-sm text-left"
        :class="
          message.user_to_pro
            ? 'self-end bg-secondary-30 rounded-br-none text-white'
            : isAuto
            ? 'self-start bg-gray-light rounded-bl-none text-gray-smoke italic'
            : 'self-start bg-gray-cloud rounded-bl-none text-gray-smoke'
        "
        :title="
          $t('pages.interactions.chat.message_send_at', {
            datetime
          })
        "
      >
        <div
          :class="
            message.attachments != undefined && message.attachments.length > 0
              ? 'whitespace-pre-line mb-2'
              : 'whitespace-pre-line'
          "
          v-html="formattedMessage"
        ></div>
        <div v-for="attachment in message.attachments" :key="attachment.id">
          <a :href="fullAttachmentUrl(attachment)" target="blank"
            ><Icon name="link" size="lg" class="p-1 pointer-events-none" />
            <span class="italic underline break-all">{{
              attachment.label
            }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import _ from "lodash";

import ErnestIsProud from "@/assets/images/ernest-is-proud.svg?inline";

export default {
  name: "Message",

  components: {
    ErnestIsProud
  },

  props: {
    message: {
      type: Object
    },
    pro: {
      type: Object
    }
  },

  computed: {
    ...mapState("user", ["user"]),

    avatarProps() {
      return {
        name: this.message.user_to_pro
          ? this.user.first_name.toUpperCase() +
            " " +
            this.user.last_name.toUpperCase()
          : this.pro.company,
        size: "s",
        imageUrl:
          !this.message.user_to_pro && !_.isNil(this.pro.avatar_url)
            ? process.env.VUE_APP_CMN_URL + this.pro.avatar_url
            : ""
      };
    },

    datetime() {
      const createdAt = this.$date(this.message.created_at);

      return createdAt.format("LL à LT");
    },

    isAuto() {
      // TODO - Rajouter un champ en base pour cela
      return (
        !this.message.user_to_pro &&
        this.message.message.startsWith("Ce professionnel")
      );
    },

    formattedMessage() {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return this.message.message.replace(urlRegex, url => {
        return `<span class="italic underline break-all"><a href="${url}" target="_blank">${url}</a></span>`;
      });
    }
  },

  methods: {
    fullAttachmentUrl(attachment) {
      return process.env.VUE_APP_CMN_URL + "/" + attachment.document_path;
    }
  }
};
</script>
