import Vue from "vue";
import Vuex from "vuex";
import chat from "./modules/chat";
import interaction from "./modules/interaction";
import pro from "./modules/professional";
import project from "./modules/project";
import sale from "./modules/sale";
import search from "./modules/search";
import user from "./modules/user";
import progress from "./modules/progress";
import flutter from "./modules/flutter";
import ad from "./modules/ad";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    chat,
    interaction,
    pro,
    project,
    sale,
    search,
    user,
    progress,
    flutter,
    ad
  },

  state: {
    notificationPanelIsOpened: false
  },

  mutations: {
    openNotificationPanel(state) {
      state.notificationPanelIsOpened = true;
    },
    closeNotificationPanel(state) {
      state.notificationPanelIsOpened = false;
    }
  },

  actions: {
    async multipleFetch(context, fetchArray) {
      //ensure to always get proper jwt access token & refresh token
      await this.dispatch("user/fetchMe");
      let promises = [];
      for (const item of fetchArray) {
        promises.push(
          new Promise(() => {
            item.fn(item.body);
          })
        );
      }
      return Promise.all(promises);
    }
  }
});
