import Api from "./Api";
import validator from "../schemaValidator";
import { validateInteraction } from "../schemas";

export default {
  async get(id, query) {
    const response = await Api.get("interactions/" + id, query);
    let data = validator(validateInteraction, response.data, "inteactions/get");
    return data;
  },

  async update(id, body) {
    const response = await Api.put("interactions/" + id, body);
    let data = validator(
      validateInteraction,
      response.data,
      "interactions/update"
    );
    return data;
  }
};
