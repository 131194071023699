import Api from "./Api";
import { validateGtmArray } from "../schemas";
import validator from "../schemaValidator";

export default {
  async get() {
    let response = await Api.get("gtm");
    let data = validator(validateGtmArray, response.data, "gtm/get");
    return data;
  }
};
