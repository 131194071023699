import SearchesApi from "@/services/api/Searches";
import i18n from "@/locales/index";
import _ from "lodash";

const state = () => ({
  searchArray: [],
  selectedSearch: null
});

const getters = {
  findById(state) {
    return idSearch => {
      return _.find(state.searchArray, s => {
        return s.id == idSearch;
      });
    };
  },

  getSearchLabel(state) {
    return idSearch => {
      let search = _.find(state.searchArray, s => {
        return s.id == idSearch;
      });
      if (!_.isNil(search) && !_.isNil(search.details)) {
        if (_.isNil(search.details.label)) {
          return (
            i18n
              .t(`property.${search.rent ? "rent" : "buy"}`)
              .replace(/^\w/, c => c.toUpperCase()) +
            ` - ` +
            i18n
              .t(`property.type.${search.type}`)
              .replace(/^\w/, c => c.toUpperCase()) +
            ` - ` +
            search.details.area +
            ` m² - ` +
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(search.details.budget) +
            (search.cities.length > 0 && search.cities[0].name != undefined
              ? ` - ` + search.cities[0].name
              : ``)
          );
        } else {
          return search.details.label;
        }
      }
      return "";
    };
  },

  getSearchShortLabel(state) {
    return idSearch => {
      let search = _.find(state.searchArray, s => {
        return s.id == idSearch;
      });
      if (!_.isNil(search) && !_.isNil(search.details)) {
        if (_.isNil(search.details.label)) {
          return (
            i18n
              .t(`property.${search.rent ? "rent" : "buy"}`)
              .replace(/^\w/, c => c.toUpperCase()) +
            ` - ` +
            search.details.area +
            ` m²` +
            (search.cities.length > 0 &&
              search.cities[0].postal_codes != undefined &&
              search.cities[0].postal_codes[0] != undefined
              ? ` - ` + search.cities[0].postal_codes[0]
              : ``)
          );
        } else {
          return search.details.label;
        }
      }
      return "";
    };
  },

  canActivateSearch(state) {
    return _.filter(state.searchArray, ["active", true]).length < 3;
  },

  hasNewActivePurchaseSearch(state) {
    return (
      _.filter(state.searchArray, function (search) {
        return (
          search.active &&
          !search.rent &&
          new Date().setHours(0, 0, 0, 0) ==
          new Date(search.created_at).setHours(0, 0, 0, 0)
        );
      }).length > 0
    );
  },

  hasNewInactiveSearch(state) {
    return (
      _.filter(state.searchArray, function (search) {
        return (
          !search.active &&
          new Date().setHours(0, 0, 0, 0) ==
          new Date(search.created_at).setHours(0, 0, 0, 0)
        );
      }).length > 0
    );
  }
};

const actions = {
  target({ commit, state }, { id }) {
    const search = _.find(state.searchArray, { id: id });
    if (search) {
      commit("SET_SELECTED", id);
      return search;
    } else throw new Error("search not found");
  },

  async fetch({ commit }, { id, ...query }) {
    const search = await SearchesApi.get(id, query);
    commit("REMOVE", search.id);
    commit("ADD", search);
    commit("SET_SELECTED", search.id);
    return search;
  },

  async update({ commit, dispatch }, { id, payload }) {
    try {
      const search = await SearchesApi.update(id, payload);
      await dispatch("project/fetchAds", { forcePolling: true }, { root: true });
      commit("UPDATE", search);
      commit("SET_SELECTED", search.id);
      return search;
    } catch (error) {
      console.log(error);
    }
  }
};

const mutations = {
  INIT_LIST(state, searches) {
    state.searchArray = searches;
  },

  SET_SELECTED(state, id) {
    state.selectedSearch = _.find(state.searchArray, { id: id });
  },

  ADD(state, search) {
    state.searchArray.push(search);
  },

  REMOVE(state, id) {
    state.searchArray = _.find(state.searchArray, { id: id })
      ? _.reject(state.searchArray, { id: id })
      : state.searchArray;
  },

  UPDATE(state, search) {
    state.searchArray = _.map(state.searchArray, s => {
      return s.id == search.id ? { ...search } : s;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
