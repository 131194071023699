<template>
    <div class="relative flex m-2  shadow-md rounded-sm p-2">
        <div class="flex flex-col gap-1 relative w-56">
            <Badge v-if="ad.ad_data.pictureUrls.length > 0" type="success" :count="ad.ad_data.pictureUrls.length"
                class=" absolute top-0 m-1 p-1 bg-primary-100" />
            <router-link :to="{
                name: 'AdConsult',
                params: { adId: ad.id }
            }">
                <img :src="ad.ad_data.pictureUrls[0]" class="w-full h-32 bg-no-image bg-cover" />
            </router-link>
            <div class="flex flex-col items-center">
                <div class="flex w-full lg:w-2/3 font-semibold text-sm gap-2 items-center">
                    <Icon name="map-marker" class="w-5" />
                    <span class="truncate">{{ ad.ad_data.location.postalCode + ' ' + ad.ad_data.location.city }}</span>
                </div>
            </div>
        </div>
        <div class="flex-1 flex flex-col mx-4 font-lato w-1">
            <div class="flex gap-3 py-3 items-center">
                <Tag>{{ $t('pages.ads.category.' + ad.ad_data.category) }}</Tag>
                <Tag class="bg-primary-50" size="m">{{ ad.ad_data.surface }} m2</Tag>
                <Tag class="bg-sunset-200" size="m">{{ formattedPrice(ad.ad_data.price) }}</Tag>
                <PriceChanged class="text-xs" v-if="ad.ad_data.options.includes('hasPriceChanged')" />
                <div class=" flex-1">
                </div>
                <AdActions :ad="ad" @on-like="like" @on-dislike="dislike" @on-contact="contact"></AdActions>
            </div>
            <p class="text-left">
                {{ ad.ad_data.title }}
            </p>
            <p class="text-left text-sm truncate">
                Classe DPE : {{ ad.ad_data.energyGrade }}
            </p>

            <div class="flex-1"></div>
            <div class="flex items-center">
                <div class="flex-1"></div>
                <span class="flex-none text-xs">publiée le {{ formattedDate(ad.published_at) }}</span>

            </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-full bg-white opacity-50 rounded-sm cursor-not-allowed"
            v-if="!ad.active"></div>
        <div v-if="!ad.active" class="filigrane absolute top-1/2 left-1/2 text-5xl text-pink-light">{{
            $t('pages.ads.disabled') |
            uppercase
        }}</div>
    </div>
</template>

<script>
import AdMixin from './AdMixin';
import AdActions from './AdActions.vue';
import PriceChanged from './PriceChanged.vue';

export default {
    name: "AdItem",
    components: {
        AdActions,
        PriceChanged
    },
    mixins: [AdMixin],
    props: {
        ad: {
            type: Object,
            required: true
        }
    }
}
</script>
<style scoped>
.filigrane {
    transform: translate(-50%, -50%) rotate(-15deg);
}
</style>

