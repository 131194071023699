import Api from "./Api";
import validator from "../schemaValidator";
import {
  validateChat,
  validateMessageArray,
  validateMessage
} from "../schemas";

export default {
  async get(id, query) {
    const response = await Api.get("chats/" + id, query);
    let data = validator(validateChat, response.data, "chats/get");
    return data;
  },

  async getMessages(id, query) {
    const response = await Api.get("chats/" + id + "/messages", query);
    let data = validator(
      validateMessageArray,
      response.data,
      "chats/getMessages"
    );
    return data;
  },

  async postMessage(id, body) {
    const response = await Api.post("chats/" + id + "/messages", body);
    let data = validator(validateMessage, response.data, "chats/postMessage");
    return data;
  }
};
