import firebase from "firebase/app";
import "firebase/messaging";

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBcnYHqnoXvFJr0HbpiyAOrHB9-xpXw5pI",
  authDomain: "cmn1-988.firebaseapp.com",
  projectId: "cmn1-988",
  storageBucket: "cmn1-988.appspot.com",
  messagingSenderId: "111597882900",
  appId: process.env.VUE_APP_FB_APP_ID
  //measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

if (firebase.messaging.isSupported()) {
  console.log("Firebase supported");
} else {
  console.log("Firebase NOT supported");
}

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default messaging;
