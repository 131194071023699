import Api from "./Api";
import validator from "../schemaValidator";
import { validateSaleDocumentArray } from "../schemas";

export default {
  async postDocuments(id, body) {
    const response = await Api.postFormData("sale/" + id + "/documents", body);
    let data = validator(
      validateSaleDocumentArray,
      response.data,
      "sale/postDocuments"
    );
    return data;
  }
};
