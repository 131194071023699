import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from "../locales";
import store from "../store";
import Interactions from "../layouts/Interactions.vue";
const NoSidebarPage = () =>
  import(/* webpackChunkName: "Page" */ "../layouts/NoSidebarPage.vue");
const Page = () => import(/* webpackChunkName: "Page" */ "../layouts/Page.vue");
import Ads from "../views/Ads.vue";
import Ad from "../views/SingleAd.vue";
import AdsList from "@/components/Ad/AdsList.vue";

import Header from "@/layouts/partials/Header";
import Breadcrumb from "@/components/Breadcrumb.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Project",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ProjectSummary" */ "../views/ProjectSummary.vue"
        ),
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      header: {
        headerNewSearchButton: true
      },
    },
    meta: {
      layout: Page
    }
  },
  {
    path: "/chats",
    name: "ProjectChats",
    components: {
      default: Interactions,
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: {
        breadcrumb: [
          {
            text: VueI18n.t("menu.project"),
            to: { name: "Project" }
          },
          {
            text: VueI18n.t("menu.interactions"),
            to: {
              name: "ProjectChats"
            }
          }
        ]
      },
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: VueI18n.t("menu.interactions"),
        headerAdditional: false
      }
    },

    meta: {
      content: () => ({
        margin: false
      }),
      layout: Page
    }
  },
  {
    path: "/interactions/:chatId?/(informations)?",
    name: "ProjectInteractions",
    components: {
      default: Interactions,
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: function (route) {
        return {
          breadcrumb: [
            {
              text: VueI18n.t("menu.project"),
              to: { name: "Project" }
            },
            {
              text: VueI18n.t("menu.interactions"),
              to: {
                name: "ProjectInteractions",
                params: {
                  chatId: route.params.chatId,
                  pathMatch: "informations"
                }
              }
            }
          ]
        };
      },
      header: function (route) {
        if (route.params.chatId) {
          if (route.params.pathMatch === "informations") {
            return {
              backTo: {
                name: "ProjectInteractions",
                params: { chatId: route.params.chatId }
              },
              headerRight: true,
              headerTitle: true,
              headerAdditional: false
            };
          } else {
            return {
              backTo: { name: "ProjectChats" },
              headerRight: true,
              headerTitle: true,
              headerAdditional: false
            };
          }
        }
        return {
          backTo: { name: "Project" },
          headerRight: false,
          headerTitle: VueI18n.t("menu.interactions"),
          headerAdditional: false
        };
      }
    },

    meta: {
      content: () => ({
        margin: false
      }),
      layout: Page
    }
  },
  {
    path: "/recherches",
    name: "ProjectSearches",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ProjectSearches" */ "../views/ProjectSearches.vue"
        ),
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: {
        breadcrumb: [
          {
            text: VueI18n.t("menu.project"),
            to: { name: "Project" }
          }
        ]
      },
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: VueI18n.t("menu.searches"),
        headerAdditional: false
      }
    },
    meta: {
      layout: Page
    }
  },
  {
    path: "/annonces",
    name: "Ads",
    components: {
      default: Ads,
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: {
        breadcrumb: [
          {
            text: VueI18n.t("menu.project"),
            to: { name: "Project" }
          },
          {
            text: VueI18n.t("menu.ads"),
            to: { name: "Ads" }
          }
        ]
      },
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: VueI18n.t("menu.ads"),
        headerAdditional: false
      }
    },
    children: [
      {
        path: "all",
        name: 'AllAds',
        component: AdsList,
        props: () => {
          return { adArray: store.getters["ad/sortedAds"] }
        },
        meta: {
          content: () => ({
            margin: true
          }),
          layout: Page
        },
      },
      {
        path: "liked",
        name: 'LikedAds',
        component: AdsList,
        props: () => {
          return { adArray: store.getters["ad/likedAds"] }
        },
        meta: {
          content: () => ({
            margin: true
          }),
          layout: Page
        },

      },
      {
        path: "disliked",
        name: 'DislikedAds',
        component: AdsList,
        props: () => {
          return { adArray: store.getters["ad/dislikedAds"] }
        },
        meta: {
          content: () => ({
            margin: true
          }),
          layout: Page
        },

      },
      {
        path: "contacted",
        name: 'ContactedAds',
        component: AdsList,
        props: () => {
          return { adArray: store.getters["ad/contactedAds"] }
        },
        meta: {
          content: () => ({
            margin: true
          }),
          layout: Page
        },

      }
    ],
    meta: {
      content: () => ({
        margin: true
      }),
      layout: Page
    }
  },

  {
    path: "/annonces/:adId",
    name: "AdConsult",
    components: {
      default: Ad,
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: function () {
        return {
          breadcrumb: [
            {
              text: VueI18n.t("menu.project"),
              to: { name: "Project" }
            },
            {
              text:
                store.getters["ad/getTitle"] ||
                VueI18n.t("menu.ads"),
              to: { name: "ProjectSearch" }
            }
          ]
        };
      },
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: VueI18n.t("menu.ads"),
        headerAdditional: false
      }
    },
    meta: {
      layout: Page
    }
  },
  {
    path: "/recherches/:searchId/:action?",
    name: "ProjectSearch",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "ProjectSearch" */ "../views/ProjectSearch.vue"
        ),
      header: Header,
      breadcrumb: Breadcrumb
    },
    props: {
      breadcrumb: function (route) {
        return {
          breadcrumb: [
            {
              text: VueI18n.t("menu.project"),
              to: { name: "Project" }
            },
            {
              text:
                store.getters["search/getSearchLabel"](route.params.searchId) ||
                VueI18n.t("menu.search"),
              to: { name: "ProjectSearch" }
            }
          ]
        };
      },
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: VueI18n.t("menu.search"),
        headerAdditional: false
      }
    },
    meta: {
      layout: Page
    }
  },
  {
    path: "/parametres/:setting?",
    name: "Settings",

    components: {
      default: () =>
        import(/* webpackChunkName: "Settings" */ "../views/Settings.vue"),
      header: Header
    },

    props: {
      header: {
        backTo: { name: "Project" },
        headerRight: false,
        headerTitle: "Paramètres",
        headerAdditional: false
      }
    },

    meta: {
      layout: NoSidebarPage
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(to => {
  window.dataLayer.push({ event: to.name });
});

export default router;
