import Api from "./Api";
import {
  validateProjectArray,
  validateProject,
  validateInteractionArray,
  validateSearchArray,
  validateChatArray,
  validateSale,
  validateProArray,
  validateProgressArray
} from "../schemas";
import validator from "../schemaValidator";

export default {
  async getAll() {
    const response = await Api.get("projects");
    let data = validator(
      validateProjectArray,
      response.data,
      "projects/getAll"
    );
    return data;
  },

  async get(id, query) {
    const response = await Api.get("projects/" + id, query);
    let data = validator(validateProject, response.data, "projects/get");
    return data;
  },

  async update(id, body) {
    body = validator(validateProject, body);
    const response = await Api.put(
      "projects/" + id,
      body,
      "projects/update/body"
    );
    let data = validator(validateProject, response.data, "projects/update");
    return data;
  },

  async getInteractions(id, query) {
    const response = await Api.get("projects/" + id + "/interactions", query);
    let data = validator(
      validateInteractionArray,
      response.data,
      "projects/getInteractions"
    );
    return data;
  },

  async getSearches(id, query) {
    const response = await Api.get("projects/" + id + "/searches", query);
    let data = validator(
      validateSearchArray,
      response.data,
      "projects/getSearches"
    );
    return data;
  },

  async getChats(id, query) {
    const response = await Api.get("projects/" + id + "/chats", query);
    let data = validator(validateChatArray, response.data, "projects/getChats");
    return data;
  },

  async getPros(id, query) {
    const response = await Api.get("projects/" + id + "/pros", query);
    let data = validator(validateProArray, response.data, "projects/getPros");
    return data;
  },

  async getSale(id, query) {
    const response = await Api.get("projects/" + id + "/sale", query);
    let data = response.data
      ? validator(validateSale, response.data, "projects/getSale")
      : null;
    return data;
  },

  async getProgress(id, query) {
    const response = await Api.get("projects/" + id + "/progress", query);
    let data = validator(
      validateProgressArray,
      response.data,
      "projects/getProgresses"
    );
    return data;
  }
};
