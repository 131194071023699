<template>
  <div
    v-show="chat !== null && pro != null"
    class="flex flex-row w-full leading-5"
  >
    <Avatar
      :name="companyName"
      :imageUrl="avatarUrl"
      class="flex-shrink-0 ml-2 mr-4"
    />
    <div class="flex flex-col justify-center items-start font-sans text-sm">
      <span class="font-bold leading-8 text-gray-smoke">{{ companyName }}</span>
      <span class="text-gray-elephant">
        {{
          $t("pages.interactions.last_exchanges_at", {
            at: $date(lastActivityAt).fromNow()
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "InteractionInfoShort",

  props: {
    chat: {
      type: Object,
      default: null
    },

    pro: {
      type: Object,
      default: null
    }
  },

  computed: {
    companyName() {
      return !_.isNil(this.pro) ? this.pro.company : "";
    },

    avatarUrl() {
      return !_.isNil(this.pro) && !_.isNil(this.pro.avatar_url)
        ? process.env.VUE_APP_CMN_URL + this.pro.avatar_url
        : "";
    },

    lastActivityAt() {
      return !_.isNil(this.chat) ? this.chat.updated_at : null;
    }
  }
};
</script>
