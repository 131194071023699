import flutterChannel from "@/flutterChannel";

const state = () => ({
  flutterChannelReady: false,
  flutterMessageQueue: []
});

const getters = {};

const actions = {
  async flutterUnqueueMessage({ commit, state }) {
    if (state.flutterChannelReady) {
      for (const message of state.flutterMessageQueue.reverse()) {
        flutterChannel.postMessage(message);
      }
      commit("CLEAN_FLUTTER_QUEUE");
    }
  },

  async flutterQueueMessage({ commit, dispatch }, message) {
    commit("SET_FLUTTER_MESSAGE", message);
    await dispatch("flutterUnqueueMessage");
  },

  async flutterReady({ commit, dispatch }) {
    commit("SET_FLUTTER_CHANNEL_READY");
    await dispatch("flutterUnqueueMessage");
  }
};

const mutations = {
  SET_FLUTTER_CHANNEL_READY(state) {
    state.flutterChannelReady = true;
  },

  CLEAN_FLUTTER_QUEUE(state) {
    state.flutterMessageQueue = [];
  },

  SET_FLUTTER_MESSAGE(state, message) {
    state.flutterMessageQueue.push(message);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
