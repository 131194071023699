import ChatsApi from "@/services/api/Chats";
import _ from "lodash";

const state = () => ({
  chatArray: [],
  selectedChat: null,
  messageArray: []
});

const getters = {
  sortedChats(state) {
    return _.orderBy(state.chatArray, ["updated_at"], ["desc"]);
  },

  sortedMessages(state) {
    return _.sortBy(state.messageArray, ["created_at"], ["desc"]);
  },

  findById(state) {
    return id => _.find(state.chatArray, { id: id });
  },

  getMessageAttachements(state) {
    let attachments = [];
    _.forEach(state.messageArray, function(message) {
      _.forEach(
        _.orderBy(message.attachments, ["updated_at"], ["desc"]),
        function(attachment) {
          attachments.push(attachment);
        }
      );
    });
    return attachments;
  }
};

const actions = {
  async fetchMessages({ commit, dispatch }, { id }) {
    const messages = await ChatsApi.getMessages(id);
    commit("INIT_LIST_MESSAGES", messages);
    dispatch("interaction/setViewedByChatId", { chat_id: id }, { root: true });
    return messages;
  },

  async target({ state, commit, dispatch }, { id }) {
    const chat = _.find(state.chatArray, { id: id });
    if (chat && (!state.selectedChat || state.selectedChat.id != id)) {
      commit("SET_SELECTED", id);
      commit("pro/SET_SELECTED", chat.pro_id, { root: true });
      await dispatch("fetchMessages", { id: id });
    }
    return chat;
  },

  async fetch({ commit, dispatch }, { id, ...query }) {
    const chat = await ChatsApi.get(id, query);
    commit("REMOVE_CHAT", chat.id);
    commit("ADD_CHAT", chat);
    commit("SET_SELECTED", chat.id);
    commit("pro/SET_SELECTED", chat.pro_id, { root: true });
    await dispatch("fetchMessages", { id: id });
    return chat;
  },

  async postMessage({ commit }, { id, ...body }) {
    const message = await ChatsApi.postMessage(id, body);
    commit("ADD_MESSAGE", message);
    return message;
  }
};

const mutations = {
  INIT_LIST_CHAT(state, chats) {
    state.chatArray = chats;
  },
  INIT_LIST_MESSAGES(state, messages) {
    state.messageArray = messages;
  },
  SET_SELECTED(state, id) {
    const chat = _.find(state.chatArray, { id: id });
    if (chat) state.selectedChat = chat;
  },
  ADD_CHAT(state, chat) {
    state.chatArray.push(chat);
  },
  ADD_MESSAGE(state, message) {
    state.messageArray.push(message);
  },
  REMOVE_CHAT(state, id) {
    state.chatArray = _.find(state.chatArray, { id: id })
      ? _.reject(state.chatArray, { id: id })
      : state.chatArray;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
