function parse(value, fallback) {
  if (typeof value === "undefined") {
    return fallback;
  }
  switch (typeof fallback) {
    case "boolean":
      return !!JSON.parse(value);
    case "number":
      return JSON.parse(value);
    default:
      return value;
  }
}

const config = {
  apiBaseUrl: parse(
    process.env.VUE_APP_API_BASE_URL,
    "http://localhost:8080/api/"
  ),
  locale: parse(process.env.VUE_APP_LOCALE, "fr"),
  features: {}
};

function feature(name) {
  return config.features[name];
}

export { config };

export default {
  install(Vue) {
    Vue.appConfig = config;
    Vue.feature = feature;
    Vue.prototype.$appConfig = config;
    Vue.prototype.$feature = feature;
  }
};
