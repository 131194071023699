<template>
  <div v-if="!loading" class="flex-1 flex flex-col">
    <div
      class="hidden -mt-16 px-12 py-8 font-lato font-black text-left text-3xl text-gray-dark lg:block"
    >
      {{ $tc("pages.interactions.title") | capitalize }}
    </div>
    <div
      class="flex-1 relative flex flex-row justify-between bg-white lg:bg-gray-light lg:border-t lg:border-gray-mouse"
    >
      <div
        class="absolute flex-row w-full h-full lg:relative lg:flex lg:w-1/4 lg:h-auto"
        :class="!$route.params.chatId ? 'flex' : 'hidden'"
      >
        <List :chats="chats" :pros="proArray" />
      </div>
      <div
        class="absolute w-full h-full lg:relative lg:h-auto lg:flex-1 lg:block lg:bg-white"
        :class="
          $route.params.chatId && $route.params.pathMatch !== 'informations'
            ? 'flex'
            : 'hidden'
        "
      >
        <ChatView
          :chat="selectedChat"
          :interactions="interactions"
          :message.sync="message"
          :messages="sortedChatMessages"
          :key="chatViewKey"
          @send="sendMessage"
          class="flex-1"
        />
      </div>
      <div
        class="absolute w-full h-full lg:relative lg:block lg:w-1/6 lg:h-auto"
        :class="$route.params.pathMatch === 'informations' ? 'block' : 'hidden'"
      >
        <Informations
          :chat="selectedChat"
          :pro="selectedPro"
          :interactions="interactions"
        />
      </div>
    </div>
    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
  </div>
  <div v-else class="mt-4">
    <Loading />
  </div>
</template>

<script>
import ChatView from "../components/interaction/Chat.vue";
import Informations from "../components/interaction/Informations.vue";
import List from "../components/interaction/List.vue";
import Loading from "../components/Loading.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Interactions",

  components: {
    ChatView,
    Informations,
    List,
    Loading
  },

  data() {
    return {
      loading: true,
      message: "",
      showSnackbar: false,
      hasError: false,
      chatViewKey: 0
    };
  },

  computed: {
    ...mapState("chat", ["chatArray", "selectedChat"]),
    ...mapState("pro", ["selectedPro", "proArray"]),
    ...mapState("interaction", ["userMessageViewGtmToSend"]),
    ...mapGetters("chat", {
      sortedChatMessages: "sortedMessages"
    }),
    ...mapGetters("interaction", {
      findInteractionsByProId: "findByProId"
    }),
    ...mapGetters("user", ["gtmProfile"]),

    chats() {
      return this.chatArray;
    },

    interactions() {
      if (!_.isNil(this.selectedPro))
        return this.findInteractionsByProId(this.selectedPro.id);
      return [];
    },
    snackbarProps() {
      let props = {
        variant: "success",
        text: "Votre message vient d'être envoyé !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible d'envoyer votre message."
        };
      }

      return props;
    }
  },

  methods: {
    ...mapActions("chat", {
      targetChat: "target",
      fetchChat: "fetch"
    }),

    async sendMessage() {
      try {
        await this.$store.dispatch("chat/postMessage", {
          id: this.$route.params.chatId,
          message: this.message
        });

        this.message = "";
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.showSnackbar = true;
      } finally {
        this.loading = false;

        this.$gtm.trackEvent({
          event: "user-message-answer",
          eventCategory: "User Message",
          eventAction: "User Message Answer",
          eventLabel: this.gtmProfile,
          eventValue: "",
          noninteraction: false
        });
      }
    },

    trackMessageView() {
      if (this.userMessageViewGtmToSend) {
        this.$gtm.trackEvent({
          event: "user-message-view",
          eventCategory: "User Message",
          eventAction: "User Message View",
          eventLabel: this.gtmProfile,
          eventValue: "",
          noninteraction: false
        });
        this.$store.commit("interaction/USER_MESSAGE_VIEW_GTM_TO_SEND", false);
      }
    },

    forceRerenderChatView() {
      this.chatViewKey += 1;
    }
  },

  async mounted() {
    if (this.$route.params.chatId) {
      await this.fetchChat({ id: this.$route.params.chatId });
      this.loading = false;
      this.trackMessageView();
    } else this.loading = false;
  },

  beforeRouteLeave(to, from, next) {
    if (to.params.chatId) {
      this.loading = true;
      this.fetchChat({ id: to.params.chatId }).then(() => {
        this.loading = false;
        this.trackMessageView();
        this.forceRerenderChatView();
        next();
      });
    } else next();
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.chatId)
      this.targetChat({ id: to.params.chatId }).then(() => {
        this.trackMessageView();
        next();
      });
    else next(false);
  }
};
</script>

<style scoped></style>
