<template>
    <div v-if="!isPolling" class="flex-1 flex flex-col">
        <div class="hidden -mt-8 px-12 py-8 font-lato font-black text-left text-3xl text-gray-dark lg:block">
            {{ $tc("pages.summary.project_related_ads") | capitalize }}
        </div>
        <div class="flex-1 flex flex-col bg-white lg:bg-gray-light lg:border-gray-mouse gap-4">
            <div
                class="flex flex-wrap font-lato text-xs md:text-base text-center border-b-1/2 border-gray-elephant items-center gap-1">
                <router-link v-for="link, index in links" :key="index" :to="link.to" active-class="active"
                    class="px-2 py-2 md:p-3 rounded-t hover:text-white hover:bg-gray-smoke">{{
                        link.text }}</router-link>
            </div>

            <router-view></router-view>

        </div>
    </div>
    <div v-else class="mt-4">
        <Loading>Recherche d'annonces correspondant à votre projet</Loading>
    </div>
</template>

<script>
import Loading from "../components/Loading.vue";

import { mapState, mapGetters } from "vuex";
export default {
    name: "Ads",
    components: {
        Loading,
    },
    computed: {
        ...mapState("ad", ["adArray", "loading"]),
        ...mapGetters("ad", [
            "sortedAds",
            "likedAds",
            "dislikedAds",
            "contactedAds",
            "isPolling"
        ]),
        links() {
            return [
                {
                    to: { name: "AllAds" },
                    text: `${this.$t("pages.ads.tabs.all")} (${this.sortedAds.length})`,
                },
                {
                    to: { name: "LikedAds" },
                    text: `${this.$t("pages.ads.tabs.liked")} (${this.likedAds.length})`,
                },
                {
                    to: { name: "DislikedAds" },
                    text: `${this.$t("pages.ads.tabs.disliked")} (${this.dislikedAds.length})`,
                },
                {
                    to: { name: "ContactedAds" },
                    text: `${this.$t("pages.ads.tabs.contacted")} (${this.contactedAds.length})`,
                },
            ]
        }
    }

};

</script>
<style>
.active {
    @apply text-white bg-gray-smoke;
}
</style>
