<template>
  <button
    class="relative h-full w-10 rounded-full text-gray-smoke cursor-pointer hover:bg-gray-dark hover:bg-opacity-10 focus:bg-gray-dark focus:bg-opacity-10 focus:outline-none"
    @click.stop.prevent="onClick"
  >
    <Dot
      v-if="hasNewInteractions"
      type="danger"
      class="absolute top-0 right-0 m-2"
    />
    <Icon name="bell" class="w-10 p-1 pointer-events-none" />
  </button>
</template>

<script>
export default {
  name: "NotificationButton",

  props: {
    hasNewInteractions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick() {
      this.$store.commit("openNotificationPanel");
    }
  }
};
</script>
