<template>
  <div v-if="chat" class="flex flex-col justify-between h-full font-sans">
    <div ref="chatbox" class="flex flex-col p-4" style="flex: 1 1 1px">
      <div
        v-for="(message, index) in messages"
        :key="message.id"
        class="mb-4 last:mb-0"
      >
        <div
          v-if="
            index == 0 ||
              shouldDisplayDate(
                message.created_at,
                messages[index - 1].created_at
              )
          "
          class="text-center text-xs text-gray-wall pb-1"
        >
          {{ $date(message.created_at).format("LLL") }}
        </div>
        <Message v-bind="{ message, pro: selectedPro }" />
      </div>
      <div class="h-12" id="chat-bottom"></div>
    </div>
    <div
      class="sticky bottom-0 p-3 bg-white"
      v-if="selectedPro.chat_reachable"
      @click="scrollToBottom(1000)"
    >
      <div
        class="flex flex-row items-center p-1 bg-gray-light rounded-xxl border-primary border-2"
      >
        <textarea
          :value="message"
          @input="$emit('update:message', $event.target.value)"
          @keyup.enter.exact="send"
          :placeholder="$t('pages.interactions.chat.placeholder') | capitalize"
          class="flex-1 mx-3 my-2 bg-transparent font-sans font-medium text-sm text-gray-smoke outline-none overflow-hidden resize-none placeholder-gray-wall autoExpand"
          style="line-height: 1.5;"
          rows="2"
          data-min-rows="2"
          maxlength="255"
        ></textarea>
        <button
          class="flex justify-center items-center w-8 h-8 bg-primary rounded-full text-white focus:outline-none"
          :class="message.trim().length === 0 ? 'opacity-25' : ''"
          :disabled="message.trim().length === 0"
          @click="send"
        >
          <Icon name="paper-plane" size="md" class="transform -rotate-30" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "./chat/Message.vue";
import _ from "lodash";
import { mapState } from "vuex";

export const INTERACTION_TYPES = {
  PHONE: 1,
  MAIL: 2,
  PROPOSAL: 3
};

export default {
  name: "Chat",

  components: {
    Message
  },

  props: {
    chat: {
      type: Object
    },

    interactions: {
      type: Array,
      default: () => []
    },

    message: {
      type: String,
      default: ""
    },

    messages: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState("pro", ["selectedPro"])
  },

  methods: {
    shouldDisplayDate(date, dateLast) {
      if (this.$date(date).diff(dateLast, "minute") > 4) {
        return true;
      }
      return false;
    },

    scrollToBottom(delay = 0) {
      setTimeout(() => {
        this.$nextTick(() => {
          if (document.getElementById("chat-bottom")) {
            window.scrollBy(
              0,
              document.getElementById("chat-bottom").offsetTop
            );
          }
        });
      }, delay);
    },

    send(event) {
      if (this.message.trim().length > 0) {
        this.$emit("send", event);
      }
    }
  },

  mounted() {
    this.scrollToBottom();
  },

  watch: {
    chat: function(newValue, oldValue) {
      if (
        !_.isNil(newValue) &&
        !_.isNil(oldValue) &&
        newValue.id !== oldValue.id
      ) {
        this.message = "";
        this.lastDateDisplayed = undefined;
      }
    },

    messages: function() {
      this.scrollToBottom();
    }
  }
};

function getScrollHeight(elm) {
  var savedValue = elm.value;
  elm.value = "";
  elm._baseScrollHeight = elm.scrollHeight;
  elm.value = savedValue;
}

function onExpandableTextareaInput({ target: elm }) {
  // make sure the input event originated from a textarea and it's desired to be auto-expandable
  if (!elm.classList.contains("autoExpand") || !elm.nodeName == "TEXTAREA")
    return;

  var minRows = elm.getAttribute("data-min-rows") | 0,
    rows;
  !elm._baseScrollHeight && getScrollHeight(elm);

  elm.rows = minRows;
  rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 16);
  elm.rows = minRows + rows;
}

// global delegated event listener
document.addEventListener("input", onExpandableTextareaInput);
</script>
