<template>
    <div>
        <div v-for="ad in adArray" :key="ad.id" :ad="ad">
            <AdItem class="hidden md:flex" :ad="ad" />
            <AdMini class="w-4/5 mx-auto md:hidden" :ad="ad" />
        </div>
    </div>
</template>

<script>
import AdItem from "@/components/Ad/AdItem.vue";
import AdMini from "@/components/Ad/AdMini.vue";
export default {
    name: "AdsList",
    components: {
        AdItem,
        AdMini,
    },
    props: {
        adArray: {
            type: Array,
            required: true,
        },
    },

}
</script>