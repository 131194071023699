import UsersApi from "@/services/api/Users";
import _ from "lodash";

const state = () => ({
  user: null,
  isBuyer: true,
  hasPropertyForSale: false,
  isQualified: false,
  unsignedDocumentArray: [],
  signedDocumentArray: []
});

const getters = {
  gtmProfile: function (state) {
    return state.isBuyer ? (state.hasPropertyForSale ? "AV" : "A") : "L";
  },
};

const actions = {
  async fetchMe({ commit, dispatch }, query) {
    try {
      const user = await UsersApi.getMe(query);
      commit("UPDATE", user);
      return user;
    } catch (error) {
      //mobile notify deconnection
      await dispatch(
        "flutter/flutterQueueMessage",
        { action: "logout" },
        { root: true }
      );

      window.location.replace(process.env.VUE_APP_CMN_URL + "/deconnexion");
    }
  },

  async fetchProject({ commit }, { id }) {
    const projectArray = await UsersApi.getProjects(id);
    commit("project/INIT_LIST", projectArray, { root: true });
    if (projectArray.length > 0)
      commit("project/SET_SELECTED", projectArray[0].id, { root: true });
    return projectArray;
  },

  async fetchDocuments({ commit }, { id }) {
    const documentArray = await UsersApi.getDocuments(id);
    commit("INIT_DOCUMENT_LIST", documentArray);
    return documentArray;
  },

  async update({ commit }, { id, body }) {
    const user = await UsersApi.update(id, body);
    commit("UPDATE", user);
    return user;
  },

  async updatePassword({ state }, { id, body }) {
    await UsersApi.updatePassword(id, body);
    return state.user;
  },

  async logout({ dispatch }) {
    await dispatch(
      "flutter/flutterQueueMessage",
      { action: "logout" },
      { root: true }
    );
    return UsersApi.logout();
  },

  async updateFcmToken(context, body) {
    await UsersApi.updateFcmToken(body);
  },

  async appInstall() {
    let url = await UsersApi.appInstall();
    window.open(url, "_blank");
  },

  async hubspotCallBack(context, body) {
    await UsersApi.hubspotCallBack(body);
  },

  async signDocument(context, body) {
    await UsersApi.signDocument(body);
  },

  async setSignedDocument(context, body) {
    await UsersApi.setSignedDocument(body);
    await context.dispatch("fetchDocuments", { id: context.state.user.id });
  }
};

const mutations = {
  UPDATE(state, user) {
    state.user = user;
  },

  SET_IS_BUYER(state, searches) {
    state.isBuyer =
      searches.length == 0 || _.find(searches, ["rent", false]) !== undefined;
  },

  SET_HAS_PROPERTY_FOR_SALE(state, searches) {
    state.hasPropertyForSale =
      _.find(searches, ["property_for_sale", true]) !== undefined;
  },

  SET_IS_QUALIFIED(state, searches) {
    state.isQualified =
      _.find(searches, ["qualified", true]) !== undefined;
  },

  INIT_DOCUMENT_LIST(state, documents) {
    documents = _.filter(documents, ["type", "mandate"]);
    state.signedDocumentArray = [..._.reject(documents, ["signed_at", null])];
    state.unsignedDocumentArray = [..._.filter(documents, ["signed_at", null])];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
