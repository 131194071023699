import qs from "qs";
import { config } from "@/config";
import store from "@/store";

export const apiFetch = (fetch => {
  return async (input, init) => {
    const headers = new Headers(init ? init.headers : {});

    input = config.apiBaseUrl + input;

    init = {
      ...init,
      headers: headers
    };

    const response = await fetch(input, init);
    if (!response.ok) {
      //may authentication be compromised ?
      if (response.status == 401) {
        //mobile notify deconnection
        await store.dispatch("flutter/flutterQueueMessage", {
          action: "logout"
        });

        window.location.replace(process.env.VUE_APP_CMN_URL + "/deconnexion");
      }
      throw response;
    }

    const res = await response.json();
    if (
      res.metadata &&
      res.metadata.access_token &&
      res.metadata.refresh_token
    ) {
      //mobile auth data update
      const flutterMessage = {
        action: "updateAuthTokens",
        jwt_access_token: res.metadata.access_token,
        refresh_token: res.metadata.refresh_token
      };

      await store.dispatch("flutter/flutterQueueMessage", flutterMessage);
    }
    return res;
  };
})(fetch);

export default {
  get(endpoint, query) {
    if (query) {
      const params = new URLSearchParams(
        qs.stringify(query, { arrayFormat: "brackets" })
      );
      endpoint += "?" + params;
    }

    return apiFetch(endpoint, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  },

  post(endpoint, body) {
    return apiFetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  },

  postFormData(endpoint, body) {
    return apiFetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: body
    });
  },

  put(endpoint, body) {
    return apiFetch(endpoint, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  },

  delete(endpoint) {
    return apiFetch(endpoint, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  }
};
