import Api from "./Api";
import { validateUser, validateProjectArray, validateUserDocumentArray } from "../schemas";
import validator from "../schemaValidator";

export default {
  async getMe(query) {
    let response = await Api.get("users/me", query);
    let data = validator(validateUser, response.data, "users/getMe");
    return data;
  },

  async getProjects(id, query) {
    let response = await Api.get("users/" + id + "/projects", query);
    let data = validator(
      validateProjectArray,
      response.data,
      "users/getProjects"
    );
    return data;
  },

  async getDocuments(id, query) {
    let response = await Api.get("users/" + id + "/documents", query);
    let data = validator(
      validateUserDocumentArray,
      response.data,
      "users/getDocuments"
    );
    return data;
  },

  async update(id, body) {
    let response = await Api.put(`users/${id}`, body);
    let data = validator(validateUser, response.data);
    return data;
  },

  async logout() {
    return await Api.get(`users/logout`);
  },

  async updatePassword(id, body) {
    return await Api.put(`users/${id}/password`, body);
  },

  async updateFcmToken(body) {
    try {
      let response = await Api.post(`users/fcm_token`, body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  async appInstall() {
    let response = await Api.get(`users/app_install`);
    return response.data;
  },

  async hubspotCallBack(body) {
    try {
      let response = await Api.post(`users/hubspot_callback`, body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  async signDocument(body) {
    try {
      let response = await Api.post(`users/sign_document`, body);
      if (response.data.signing_details && response.data.signing_details.signers && response.data.signing_details.signers.length > 0 && response.data.signing_details.signers[0].signature_link) {
        window.location.replace(response.data.signing_details.signers[0].signature_link);
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  async setSignedDocument(body) {
    try {
      let response = await Api.post(`users/set_signed_document`, body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
};
