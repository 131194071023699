import Api from "./Api";
import validator from "../schemaValidator";
import { validateProgress } from "../schemas";

export default {
  async get(id, query) {
    const response = await Api.get("progress/" + id, query);
    let data = validator(validateProgress, response.data, "progress/get");
    return data;
  },

  async update(id, body) {
    const response = await Api.put("progress/" + id, body);
    let data = validator(validateProgress, response.data, "progress/update");
    return data;
  }
};
