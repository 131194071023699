<template>
  <header
    class="flex flex-col w-full min-h-16 px-2 bg-white shadow-header lg:px-7 lg:shadow-md"
  >
    <div
      class="flex-auto flex-shrink-0 flex flex-row h-16 justify-between items-center lg:h-12"
    >
      <!-- left -->
      <div
        class="flex-1 flex justify-center items-center h-12 text-gray-smoke lg:justify-start"
      >
        <!-- mobile -->
        <div
          class="flex w-10 h-full justify-center items-center text-gray-smoke text-opacity-50 ml-2 lg:hidden"
        >
          <a v-if="canGoBack" @click.stop="goBack" href="#" class="h-8">
            <Icon name="chevron" class="transform rotate-90" />
          </a>
          <Button
            v-else
            size="lg"
            appearance="invert"
            icon
            @click="toggleSidebarMenu()"
          >
            <Icon name="bars" class="text-gray-smoke text-opacity-50" />
            <Badge
              v-if="nbNewInteractions > 0"
              type="danger"
              :count="nbNewInteractions"
              class="absolute top-0 left-0 mt-4 ml-10 py-1"
            />
          </Button>
        </div>
        <!-- desktop -->
        <div class="hidden h-full lg:flex items-center">
          <Button
            v-if="shouldDisplayNewSearchButtonOnDesktop"
            @click="cmnSearchUrl()"
            size="lg"
            shape="pills"
            appearance="shadow"
          >
            <Icon name="plus" size="lg" class="mr-3" />
            {{ $t("header.new_search") | capitalize }}
          </Button>
          <router-link v-else to="/" class="h-8">
            <LogoCherchemonnidFull class="h-full" />
          </router-link>
        </div>
      </div>

      <div class="flex-auto w-full h-12 leading-9 lg:w-auto">
        <div class="flex h-full justify-center items-center lg:hidden">
          <span class="capitalize" v-if="typeof headerTitle === 'string'">{{
            headerTitle
          }}</span>
          <InteractionInfoShort
            v-else-if="headerTitle"
            :chat="selectedChat"
            :pro="selectedPro"
          />
          <router-link v-else to="/" class="h-full">
            <LogoCherchemonnid class="h-full" />
          </router-link>
        </div>
        <div class="hidden h-full justify-center lg:flex">
          <!-- SearchBar -->
        </div>
      </div>

      <div
        class="flex-1 flex-shrink-0 flex justify-center h-10 text-gray-smoke text-opacity-50 lg:justify-end"
      >
        <div class="flex justify-center w-10 h-full lg:hidden">
          <InteractionInfoButton v-if="headerRight" />
          <Button
            v-if="headerNewSearchButton"
            @click="cmnSearchUrl()"
            size="lg"
            shape="full"
            :icon="true"
            appearance="shadow"
          >
            <Icon name="plus" size="lg" />
          </Button>
        </div>
        <div class="hidden lg:flex">
          <NotificationButton :has-new-interactions="hasNewInteractions" />
          <UserButton />
        </div>
      </div>
    </div>
    <div v-if="headerAdditional" class="lg:hidden">
      <InteractionInfo :search="selectedSearch" :pro="selectedPro" />
    </div>
  </header>
</template>

<script>
import NotificationButton from "@/components/header/NotificationButton.vue";
import UserButton from "@/components/header/UserButton.vue";
import InteractionInfoShort from "@/components/header/InteractionInfoShort.vue";
import InteractionInfo from "@/components/header/InteractionInfo.vue";
import InteractionInfoButton from "@/components/header/InteractionInfoButton.vue";

import LogoCherchemonnid from "@/assets/images/logo-cherchemonnid.svg?inline";
import LogoCherchemonnidFull from "@/assets/images/logo-cherchemonnid-full.svg?inline";
import { mapState, mapGetters, mapActions } from "vuex";

import _ from "lodash";

export default {
  name: "Header",

  components: {
    NotificationButton,
    UserButton,
    LogoCherchemonnid,
    LogoCherchemonnidFull,
    InteractionInfoShort,
    InteractionInfoButton,
    InteractionInfo
  },

  props: {
    backTo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    headerRight: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: [String, Boolean],
      default: false
    },
    headerAdditional: {
      type: Boolean,
      default: false
    },
    headerNewSearchButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("chat", ["selectedChat"]),
    ...mapState("pro", ["selectedPro"]),
    ...mapState("search", ["selectedSearch"]),
    ...mapGetters("interaction", ["newInteractionArray"]),

    shouldDisplayNewSearchButtonOnDesktop() {
      return ["Projects", "Settings"].indexOf(this.$route.name || "") === -1;
    },

    hasNewInteractions() {
      return this.newInteractionArray.length > 0;
    },

    nbNewInteractions() {
      return this.newInteractionArray.length;
    },

    canGoBack() {
      return !_.isEmpty(this.backTo);
    }
  },

  methods: {
    ...mapActions("user", ["fetchMe"]),

    toggleSidebarMenu() {
      this.$emit("toggleSidebarMenu");
    },

    goBack() {
      if (this.backTo != {}) {
        this.$router.push(this.backTo).catch(() => {
          this.$router.push("/");
        });
      }
    },

    async cmnSearchUrl() {
      await this.fetchMe();
      window.location.replace(process.env.VUE_APP_CMN_URL + "/recherche");
    }
  }
};
</script>
