import _ from "lodash";
import ProgressAPI from "@/services/api/Progress";

const state = () => ({
  purchase: null,
  sale: null
});

const getters = {
  saleDocuments(state) {
    return _.filter(state.sale.documents, ["sale_step", state.sale.step]);
  }
};

const actions = {
  async updateStep({ state, commit }, { purchaseMode, payload }) {
    let progressObject = purchaseMode ? state.purchase : state.sale;
    const oldStep = progressObject.step;
    const oldPros = progressObject.pro_ids;
    //PUT only if step or pro have changed
    if (oldStep !== payload.step || !_.isEqual(oldPros, payload.pro_ids)) {
      progressObject = await ProgressAPI.update(payload.id, payload);
      if (purchaseMode) commit("SET_PURCHASE", payload);
      else commit("SET_SALE", payload);
    }
    return progressObject;
  }
};

const mutations = {
  INIT(state, progresses) {
    progresses.forEach(element => {
      if (!_.isNull(element.sale_id)) {
        state.sale = element;
        state.sale.documents = state.sale.sale.documents;
        delete state.sale.sale;
      } else state.purchase = element;
    });
  },

  SET_PURCHASE(state, purchase) {
    state.purchase = { ...purchase };
  },

  SET_SALE(state, sale) {
    state.sale = { ...sale };
  },

  SET_SALE_DOCUMENTS(state, documents) {
    state.sale.documents = documents;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
