import _ from "lodash";
import dayjs from "dayjs";
import InteractionsApi from "@/services/api/Interactions";

const state = () => ({
  interactionArray: [],
  selectedInteration: null,
  userMessageViewGtmToSend: false
});

const getters = {
  findByProId(state) {
    return idPro => _.filter(state.interactionArray, ["pro_id", idPro]);
  },

  interactionCountBySearchId(state) {
    return idSearch =>
      _.filter(state.interactionArray, ["search_id", idSearch]).length;
  },

  findLastInteractionBySearchId(state) {
    return idSearch =>
      _.findLast(_.sortBy(state.interactionArray, ["id"]), [
        "search_id",
        idSearch
      ]);
  },

  newInteractionArray(state) {
    return _.orderBy(
      _.filter(state.interactionArray, ["viewed_at", null]),
      ["created_at"],
      ["desc"]
    );
  },

  oldInteractionArray(state) {
    return _.orderBy(
      _.reject(state.interactionArray, ["viewed_at", null]),
      ["created_at"],
      ["desc"]
    );
  }
};

const actions = {
  async fetch({ commit }, { id, ...query }) {
    const interaction = await InteractionsApi.get(id, query);
    commit("REMOVE", interaction.id);
    commit("ADD", interaction);
    commit("SET_SELECTED", interaction.id);
    return interaction;
  },

  async update({ commit }, { id, payload }) {
    const interaction = await InteractionsApi.update(id, payload);
    commit("UPDATE", interaction);
    commit("SET_SELECTED", interaction.id);
    return interaction;
  },

  async setViewedByChatId({ state, dispatch, commit }, { chat_id }) {
    _.forEach(
      _.filter(state.interactionArray, {
        chat_id: parseInt(chat_id),
        viewed_at: null
      }),
      function(interaction) {
        interaction.viewed_at = dayjs().format("YYYY-MM-DD HH:mm:ss");
        dispatch("update", { id: interaction.id, payload: interaction });
        commit("USER_MESSAGE_VIEW_GTM_TO_SEND", true);
      }
    );
  }
};

const mutations = {
  INIT_LIST(state, interactions) {
    state.interactionArray = interactions;
  },

  ADD(state, interaction) {
    state.interactionArray.push(interaction);
  },

  REMOVE(state, id) {
    state.interactionArray = _.find(state.interactionArray, { id: id })
      ? _.reject(state.interactionArray, { id: id })
      : state.interactionArray;
  },

  SET_SELECTED(state, id) {
    state.selectedInteration = _.find(state.interactionArray, { id: id });
  },

  UPDATE(state, interaction) {
    state.interactionArray = _.map(state.interactionArray, i => {
      return i.id == interaction.id ? { ...interaction } : i;
    });
  },

  USER_MESSAGE_VIEW_GTM_TO_SEND(state, value) {
    state.userMessageViewGtmToSend = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
