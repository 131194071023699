<template>
    <div class="flex gap-1 items-center flex-no-wrap">
        <Price class="h-1 w-1"></Price>
        <span class="whitespace-no-wrap" v-if="labelled">{{ $t('pages.ads.price_changed') }}</span>
    </div>
</template>

<script>
import Price from "@/assets/images/price.svg?inline";
export default {
    name: "PriceChanged",
    components: {
        Price
    },
    props: {
        labelled: {
            type: Boolean,
            default: true
        }
    }

}
</script>
