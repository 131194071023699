<template>
    <div class="flex gap-4 justify-around" :class="{ 'flex-row md:flex-col': appearance == 'big' }">
        <Like class="cursor-pointer" :class="classes('like')" @click="$emit('on-like', ad.id)">
        </Like>
        <Dislike class="cursor-pointer" :class="classes('dislike')" @click="$emit('on-dislike', ad.id)"></Dislike>
        <Phone class="cursor-pointer" :class="classes('contact')" @click="$emit('on-contact', ad.id)">
        </Phone>
    </div>
</template>

<script>
import Phone from "@/assets/images/phone.svg?inline";
import Like from "@/assets/images/like.svg?inline";
import Dislike from "@/assets/images/dislike.svg?inline";
export default {
    name: "AdActions",
    components: {
        Phone,
        Like,
        Dislike,
    },
    props: {
        ad: {
            type: Object,
            required: true
        },
        appearance: // big or small
        {
            type: String,
            default: "small"
        }
    },
    methods: {
        classes(tag) {
            const res = {
                selected: this.ad.tag == tag,
                'no-select': this.ad.tag == null
            }
            //appearance
            if (this.appearance == 'big') {
                res['w-24'] = true;
                res['h-24'] = true;
                res['shadow-md'] = true;
                res['bg-white'] = true;
                res['p-6'] = true;
            }
            else {
                res['w-8'] = true;
                res['h-8'] = true;
            }
            res['pointer-events-none'] = ( tag != 'contact' && this.ad.tag == 'contact');
            return res;
        }
    }

}
</script>

<style scoped>
.selected {
    opacity: 1;
}

.no-select {
    opacity: 0.3;
}

svg {
    transition: all 0.2s;
    opacity: 0.2;
}

svg:hover {
    scale: 1.2;
    opacity: 1;
}
</style>