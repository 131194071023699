
export default {
    data() {
        return {
            availableOptions: [
                "isFurnished",
                "isNew",
                "isOld",
                "hasLift",
                "hasNoLift",
                "hasGarden",
                "hasGarage",
                "hasParking",
                "hasGarageOrParking",
                "hasCave",
                "isBuildable",
                "isNotBuildable",
                "isDivisible",
                "hasSwimmingPool",
                "hasTerrace",
                "hasBalcony",
                "hasTerraceOrBalcony",
                "hasIntercom",
                "hasDigicode",
                "hasWorksRequired",
                "hasNoWorksRequired",
                "hasOpticFiber",
                "hasSeaView"
            ]
        };
    },
    methods: {
        like(adId) {
            this.$store.dispatch('ad/updateAd', { id: adId, body: { tag: this.ad.tag == 'like' ? null : 'like' } });
        },
        dislike(adId) {
            this.$store.dispatch('ad/updateAd', { id: adId, body: { tag: this.ad.tag == 'dislike' ? null : 'dislike' } });
        },
        contact(adId) {
            if (this.ad.tag !== 'contact') {
                if (this.ad.ad_data.type == 'sale')
                    this.$root.$emit('openAdContactModal', adId);
                else
                    window.open(this.ad.ad_data.url, '_blank');
            } else {
                if (this.ad.ad_data.type == 'sale')
                    this.$root.$emit('openAdContactMessageModal', this.ad.tagged_at, this.ad.message);
            }
        },
        formattedPrice(price) {
            return Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(price);
        },
        formattedDate(date) {
            return new Date(date).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            });
        },
        getCategoryLabel(trad, category) {
            switch (category) {
                case "land":
                    return trad("type.4");
                case "flat":
                    return trad("type.2");
                case "office":
                    return trad("type.8");
                case "house":
                    return trad("type.1");
            }
        }
    }
}
