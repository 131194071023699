<template>
  <router-link
    :to="{ params: { pathMatch: 'informations' } }"
    class="h-full focus:outline-none"
  >
    <Icon name="info" class="p-1 pointer-events-none" />
  </router-link>
</template>

<script>
export default {
  name: "InteractionInfoButton"
};
</script>
