import Api from "./Api";
import validator from "../schemaValidator";
import { validateSearch } from "../schemas";

export default {
  async get(id, query) {
    const response = await Api.get("searches/" + id, query);
    let data = validator(validateSearch, response.data, "searches/get");
    return data;
  },

  async update(id, body) {
    const response = await Api.put("searches/" + id, body);
    let data = validator(validateSearch, response.data, "searches/update");
    return data;
  }
};
