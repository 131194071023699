<template>
  <div class="relative" @blur.capture="handleBlur">
    <button
      @click="openMenu"
      class="h-full w-10 ml-3 rounded-full bg-white text-primary-40 shadow-md cursor-pointer hover:bg-primary-10 focus:bg-primary-10 focus:outline-none"
    >
      <Icon name="face" class="w-10 p-1" />
    </button>
    <ul
      ref="menu"
      role="menu"
      tabindex="-1"
      class="absolute top-0 right-0 w-48 -mt-2 flex flex-col bg-white rounded-xxl border border-primary-40 shadow-button font-sans text-gray-smoke text-sm outline-none overflow-hidden transform transition duration-500"
      :class="opened ? 'translate-x-0 -mr-10' : 'translate-x-full -mr-8'"
      @click="closeMenu"
      @keydown.enter="closeMenu"
    >
      <li>
        <span
          class="flex flex-row items-center px-4 py-2 font-bold text-primary-40"
        >
          <Icon name="face" size="md" class="mr-2" />{{ user.first_name }}
          {{ user.last_name }}
        </span>
      </li>
      <li>
        <router-link
          :to="{ name: 'Settings' }"
          class="flex flex-row items-center px-4 py-2 outline-none hover:bg-primary hover:text-white focus:bg-primary focus:text-white"
        >
          <Icon name="cog" size="md" class="mr-2" />Paramètres
        </router-link>
      </li>
      <li>
        <span
          tabindex="0"
          class="flex flex-row items-center px-4 py-2 outline-none hover:bg-primary hover:text-white focus:bg-primary focus:text-white cursor-pointer"
          @click="signout"
          @keydown.enter="signout"
        >
          <Icon name="sign-out" size="md" class="mr-2" />Déconnexion
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserButton",

  data: function() {
    return {
      opened: false
    };
  },

  computed: {
    menu: {
      get() {
        return this.$refs.menu;
      }
    },

    ...mapState("user", ["user"])
  },

  methods: {
    ...mapActions("user", ["logout"]),
    openMenu() {
      this.opened = true;
      this.menu.focus(); // Not sure it's required (also tabindex attr)
    },
    closeMenu() {
      this.opened = false;
    },
    handleBlur(event) {
      const relatedTarget = event.relatedTarget;
      if (this.$el.contains(relatedTarget)) return;
      this.closeMenu();
    },
    signout() {
      this.logout().then(() => {
        window.location.replace(process.env.VUE_APP_CMN_URL + "/deconnexion");
      });
    }
  }
};
</script>
