<template>
  <div class="flex flex-col items-center w-full -mt-4 leading-5">
    <Avatar size="l" :name="companyName" :imageUrl="avatarUrl" />
    <div class="mt-2 mb-4 flex flex-col">
      <div class="mb-2 font-lato font-bold text-xl text-gray-smoke">
        {{ companyName }}
      </div>
      <div class="font-sans text-xs text-center">
        <span class="font-bold text-gray-wall">
          {{ $t("pages.interactions.informations.concerned_search") }}
        </span>
        <router-link
          :to="{
            name: 'ProjectSearch',
            params: {
              searchId: search.id
            }
          }"
          class="text-avatar-basicBlue"
        >
          {{ getSearchLabel(search.id) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "InteractionInfo",
  props: {
    search: {
      type: Object,
      default: function() {
        return {};
      }
    },
    pro: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters("search", ["getSearchLabel"]),

    companyName() {
      return !_.isNil(this.pro) ? this.pro.company : "";
    },

    avatarUrl() {
      return !_.isNil(this.pro) && !_.isNil(this.pro.avatar_url)
        ? process.env.VUE_APP_CMN_URL + this.pro.avatar_url
        : "";
    }
  }
};
</script>
