import Ajv from "ajv";
import addFormats from "ajv-formats";

const ajv = new Ajv({ removeAdditional: true });
addFormats(ajv);

const user = {
  $id: "user",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    first_name: { type: "string" },
    last_name: { type: "string" },
    email: { type: "string" },
    phone: { type: "string", nullable: true },
    active: { type: "boolean" },
    public_phone: { type: "boolean" },
    public_sale: { type: "boolean" },
    phone_time_slots: { type: "array", nullable: true },
    created_at: { type: "string" }
  }
};

const realEstateDetail = {
  $id: "realEstateDetail",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    label: { type: "string", nullable: true },
    property_type: { type: "integer", nullable: true },
    room_count: { type: "integer", nullable: true },
    area: { type: "integer", nullable: true },
    budget: { type: "integer", nullable: true },
    works_type: { type: "integer", nullable: true },
    ground_area_type: { type: "integer", nullable: true },
    parking_type: { type: "integer", nullable: true },
    zip: { type: "integer", nullable: true },
    dpe_ges_rank: { type: "integer", nullable: true },
    dpe_consumption_rank: { type: "integer", nullable: true },
    details: { type: "string", nullable: true }
  }
};

const attachment = {
  $id: "attachment",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    interaction_id: { type: "integer" },
    message_id: { type: "integer" },
    document_token: { type: "string" },
    document_type: { type: "string" },
    document_path: { type: "string" },
    label: { type: "string" },
    updated_at: { type: "string" }
  }
};

const interaction = {
  $id: "interaction",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    search_id: { type: "integer" },
    pro_id: { type: "integer" },
    chat_id: { type: "integer" },
    interaction_type: { type: "integer" },
    created_at: { type: "string" },
    viewed_at: { type: "string", nullable: true }
  }
};

const search = {
  $id: "search",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    user_id: { type: "integer" },
    project_id: { type: "integer" },
    type: { type: "integer" },
    funding_type: { type: "integer", nullable: true },
    property_for_sale: { type: "boolean" },
    rent: { type: "boolean" },
    time_limit_type: { type: "integer", nullable: true },
    main_house: { type: "boolean" },
    active: { type: "boolean" },
    qualified: { type: "boolean" },
    cities: { type: "array" },
    map_url: { type: "string", nullable: true },
    real_estate_details_id: { type: "integer" },
    details: { $ref: "realEstateDetail" },
    interaction_array: {
      type: "array",
      items: { $ref: "interaction" },
      nullable: true
    },
    created_at: { type: "string" },
    updated_at: { type: "string" }
  }
};

const pro = {
  $id: "pro",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    company: { type: "string" },
    address: { type: "string" },
    zip: { type: "string" },
    city: { type: "string" },
    phone: { type: "string", nullable: true },
    email: { type: "string" },
    avatar_url: { type: "string", nullable: true },
    chat_reachable: { type: "boolean" },
    rank: { type: "integer" },
    rank_user_count: { type: "integer" }
  }
};

const saleDocument = {
  $id: "saleDocument",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    sale_id: { type: "integer" },
    document_token: { type: "string" },
    document_type: { type: "string" },
    document_path: { type: "string" },
    label: { type: "string" },
    updated_at: { type: "string" }
  }
};

const sale = {
  $id: "sale",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    detail: {
      $ref: "realEstateDetail"
    },
    type: { type: "integer", nullable: true },
    active: { type: "boolean" },
    geoloc_google_ref: { type: "string", nullable: true },
    document_array: {
      type: "array",
      items: { $ref: "saleDocument" },
      nullable: true
    }
  }
};

const project = {
  $id: "project",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    label: { type: "string" },
    interaction_count: {
      type: "object",
      nullable: true,
      properties: {
        phone: { type: "integer" },
        phone_unread: { type: "integer" },
        email: { type: "integer" },
        email_unread: { type: "integer" },
        chat: { type: "integer" },
        chat_unread: { type: "integer" }
      }
    },
    active: { type: "boolean" }
  }
};

const chat = {
  $id: "chat",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    pro_id: { type: "integer" },
    user_id: { type: "integer" },
    bookmarked: { type: "boolean" },
    updated_at: { type: "string" }
  }
};

const message = {
  $id: "message",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    index: { type: "integer" },
    message: { type: "string" },
    user_to_pro: { type: "boolean" },
    has_attachment: { type: "boolean" },
    attachments: {
      type: "array",
      items: { $ref: "attachment" },
      nullable: true
    },
    created_at: { type: "string" }
  }
};

const gtm = {
  $id: "gtm",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    user_id: { type: "integer" },
    event: { type: "string" },
    payload: { type: "object" }
  }
};

const progress = {
  $id: "progress",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    project_id: { type: "integer" },
    sale_id: { type: "integer", nullable: true },
    sale: { type: "object", nullable: true },
    pro_ids: { type: "array", nullable: true },
    step: { type: "integer" }
  }
};

const searchAd = {
  $id: "searchAd",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    search_id: { type: "integer" },
    ad_id: { type: "string" },
    ad_data: { type: "object" },
    tag: { enum: ["like", "dislike", "contact", null] },
    tagged_at: { type: "string", nullable: true },
    message: { type: "string", nullable: true },
    active: { type: "boolean" },
    published_at: { type: "string", nullable: true },
    created_at: { type: "string" },
    updated_at: { type: "string" }
  }
};

const userDocument = {
  $id: "userDocument",
  additionalProperties: false,
  type: "object",
  required: ["id"],
  properties: {
    id: { type: "integer" },
    user_id: { type: "integer" },
    type: { type: "string" },
    reference: { type: "string" },
    signed_at: { type: "string", nullable: true },
    signing_details: { type: "object", nullable: true },
    created_at: { type: "string" },
    updated_at: { type: "string" }
  }
};

const projectArray = {
  $id: "projects",
  type: "array",
  items: { $ref: "project" }
};

const interactionArray = {
  $id: "interactions",
  type: "array",
  items: { $ref: "interaction" }
};

const searchArray = {
  $id: "searches",
  type: "array",
  items: { $ref: "search" }
};

const chatArray = {
  $id: "chats",
  type: "array",
  items: { $ref: "chat" }
};

const messageArray = {
  $id: "messages",
  type: "array",
  items: { $ref: "message" }
};

const attachmentArray = {
  $id: "attachments",
  type: "array",
  items: { $ref: "attachments" }
};

const proArray = {
  $id: "pros",
  type: "array",
  items: { $ref: "pro" }
};

const gtmArray = {
  $id: "gtms",
  type: "array",
  items: { $ref: "gtm" }
};

const progressArray = {
  $id: "progresses",
  type: "array",
  items: { $ref: "progress" }
};

const saleDocumentArray = {
  $id: "saleDocuments",
  type: "array",
  items: { $ref: "saleDocument" }
};

const searchAdArray = {
  $id: "searchAds",
  type: "array",
  items: { $ref: "searchAd" }
};

const userDocumentArray = {
  $id: "userDocuments",
  type: "array",
  items: { $ref: "userDocument" }
};

export const validateUser = ajv.compile(user);
export const validateSearch = ajv
  .addSchema(realEstateDetail, "searchDetail")
  .addSchema(attachment)
  .addSchema(interaction)
  .compile(search);
export const validateInteraction = ajv.compile(interaction);
export const validateAttachment = ajv.compile(attachment);
export const validateRealEstateDetail = ajv.compile(realEstateDetail);
export const validatePro = ajv.compile(pro);
export const validateSale = ajv
  .addSchema(realEstateDetail, "saleDetail")
  .addSchema(saleDocument, "saleDocument")
  .compile(sale);
export const validateProject = ajv.compile(project);
export const validateChat = ajv.compile(chat);
export const validateMessage = ajv.compile(message);
export const validateGtm = ajv.compile(gtm);
export const validateProgress = ajv.compile(progress);
export const validateSaleDocument = ajv.compile(saleDocument);
export const validateSearchAd = ajv.compile(searchAd);
export const validateUserDocument = ajv.compile(userDocument);

export const validateProjectArray = ajv
  .addSchema(project, "oneProject")
  .compile(projectArray);
export const validateInteractionArray = ajv
  .addSchema(interaction, "oneInteraction")
  .compile(interactionArray);
export const validateSearchArray = ajv
  .addSchema(interaction, "oneSearch")
  .compile(searchArray);
export const validateChatArray = ajv
  .addSchema(chat, "oneChat")
  .compile(chatArray);
export const validateMessageArray = ajv
  .addSchema(message, "oneMessage")
  .compile(messageArray);
export const validateAttachmentArray = ajv
  .addSchema(attachment, "oneAttachment")
  .compile(attachmentArray);
export const validateProArray = ajv.addSchema(pro, "onePro").compile(proArray);
export const validateGtmArray = ajv.addSchema(gtm, "oneGtm").compile(gtmArray);
export const validateProgressArray = ajv
  .addSchema(progress, "oneProgress")
  .compile(progressArray);
export const validateSaleDocumentArray = ajv
  .addSchema(saleDocument, "oneSaleDocument")
  .compile(saleDocumentArray);
export const validateSearchAdArray = ajv
  .addSchema(searchAd, "oneSearchAd")
  .compile(searchAdArray);
export const validateUserDocumentArray = ajv
  .addSchema(userDocument, "oneUserDocument")
  .compile(userDocumentArray);
