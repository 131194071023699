var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-end",class:_vm.message.user_to_pro ? 'flex-row-reverse' : 'flex-row'},[(!_vm.isAuto)?_c('div',{class:_vm.message.user_to_pro ? 'ml-2' : 'mr-2'},[_c('Avatar',_vm._b({attrs:{"title":_vm.message.user_to_pro ? _vm.$t('pages.interactions.chat.you') : _vm.pro.company}},'Avatar',_vm.avatarProps,false))],1):_vm._e(),(_vm.isAuto)?_c('div',{staticClass:"w-9 h-9 mb-1 ml-1 mr-1"},[_c('ErnestIsProud')],1):_vm._e(),_c('div',{staticClass:"flex flex-col",staticStyle:{"max-width":"70%"}},[_c('div',{staticClass:"px-5 py-3 rounded-xxl font-sans text-sm text-left",class:_vm.message.user_to_pro
          ? 'self-end bg-secondary-30 rounded-br-none text-white'
          : _vm.isAuto
          ? 'self-start bg-gray-light rounded-bl-none text-gray-smoke italic'
          : 'self-start bg-gray-cloud rounded-bl-none text-gray-smoke',attrs:{"title":_vm.$t('pages.interactions.chat.message_send_at', {
          datetime: _vm.datetime
        })}},[_c('div',{class:_vm.message.attachments != undefined && _vm.message.attachments.length > 0
            ? 'whitespace-pre-line mb-2'
            : 'whitespace-pre-line',domProps:{"innerHTML":_vm._s(_vm.formattedMessage)}}),_vm._l((_vm.message.attachments),function(attachment){return _c('div',{key:attachment.id},[_c('a',{attrs:{"href":_vm.fullAttachmentUrl(attachment),"target":"blank"}},[_c('Icon',{staticClass:"p-1 pointer-events-none",attrs:{"name":"link","size":"lg"}}),_c('span',{staticClass:"italic underline break-all"},[_vm._v(_vm._s(attachment.label))])],1)])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }