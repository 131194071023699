import SaleAPI from "@/services/api/Sale";

const state = () => ({
  sale: null
});

const getters = {};

const actions = {
  async storeDocuments({ commit }, { id, documents }) {
    const data = await SaleAPI.postDocuments(id, documents);
    commit("progress/SET_SALE_DOCUMENTS", data, { root: true });
  }
};

const mutations = {
  UPDATE(state, sale) {
    state.sale = sale;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
