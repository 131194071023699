import Api from "./Api";
import validator from "../schemaValidator";
import { validateSearchAd, validateSearchAdArray } from "../schemas";

export default {
    async fetch() {
        const response = await Api.get("search_ads");
        let data = validator(validateSearchAdArray, response.data, "ads/fetch");
        return data;
    },

    async get(id, query) {
        const response = await Api.get("search_ads/" + id, query);
        let data = validator(validateSearchAd, response.data, "ads/get");
        return data;
    },

    async update(id, body) {
        const response = await Api.put("search_ads/" + id, body);
        let data = validator(validateSearchAd, response.data, "ads/update");
        return data;
    },
}
