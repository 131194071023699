<template>
  <div v-if="chat && pro" class="h-full bg-white text-left lg:bg-transparent">
    <div class="p-4 font-sans text-gray-smoke">
      <h4 class="mb-2 font-bold text-sm uppercase">
        {{ $t("pages.interactions.informations.contact") }}
      </h4>
      <ul>
        <li class="mb-2 font-bold text-xs break-all">
          <a :href="'mailto:' + pro.email" target="_blank">{{ pro.email }}</a>
        </li>
        <li class="flex mb-2 text-2xs">
          <Icon name="map-marker" size="sm" class="mt-1 mr-2" />
          <div class="leading-8">
            <a
              :href="
                'http://maps.google.com/?q=' +
                  pro.address +
                  ' ' +
                  pro.zip +
                  ' ' +
                  pro.city
              "
              target="_blank"
            >
              {{ pro.address }}
              <br />
              {{ pro.zip }} {{ pro.city }}
            </a>
          </div>
        </li>
        <li class="flex text-2xs">
          <Icon name="phone" size="sm" class="mt-1 mr-2" />
          <div class="leading-8">
            <a :href="'tel:' + pro.phone" target="_blank">{{ pro.phone }}</a>
          </div>
        </li>
      </ul>
    </div>

    <Divider variant="dotted-light" />

    <!-- Note -->

    <div class="p-4 font-sans text-gray-smoke">
      <h4 class="mb-2 font-bold text-sm uppercase">
        {{ $t("pages.interactions.informations.other_actions") }}
      </h4>
      <ul class="text-2xs">
        <li class="flex items-center mb-3">
          <div
            class="mr-2 flex-none justify-center items-center w-4 h-4 text-white rounded-full"
            :class="hasDisplayedPhone ? 'bg-primary' : 'bg-gray-cloud'"
          >
            <Icon name="check" size="sm" class="pl-2px" />
          </div>
          <span :class="hasDisplayedPhone ? 'opacity-100' : 'opacity-25'">
            {{
              $t(
                "pages.interactions.informations.has_retrieved_your_phone_number"
              )
            }}
          </span>
        </li>
        <li class="flex items-center">
          <div
            class="mr-2 flex-none justify-center items-center w-4 h-4 text-white rounded-full"
            :class="hasDisplayedEmail ? 'bg-primary' : 'bg-gray-cloud'"
          >
            <Icon name="check" size="sm" class="pl-2px" />
          </div>
          <span :class="hasDisplayedEmail ? 'opacity-100' : 'opacity-25'">
            {{ $t("pages.interactions.informations.has_retrieved_your_email") }}
          </span>
        </li>
      </ul>
    </div>

    <div v-if="getMessageAttachements.length > 0">
      <Divider variant="dotted-light" />
      <div class="p-4 font-sans text-gray-smoke">
        <h4 class="mb-2 font-bold text-sm uppercase">
          {{ $t("pages.interactions.informations.attachments") }}
        </h4>
        <ul class="text-2xs">
          <li
            v-for="attachment in getMessageAttachements"
            :key="attachment.id"
            class="mb-2"
          >
            <a :href="fullAttachmentUrl(attachment)" target="blank"
              ><Icon name="link" size="lg" class="p-1 pointer-events-none" />
              <span class="italic underline">{{ attachment.label }}</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export const INTERACTION_TYPES = {
  PHONE: 1,
  MAIL: 2,
  PROPOSAL: 3
};

export default {
  name: "Informations",

  props: {
    chat: {
      type: Object,
      defaut: undefined
    },
    pro: {
      type: Object,
      default: undefined
    },
    interactions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters("chat", ["getMessageAttachements"]),

    hasDisplayedPhone() {
      return (
        _.find(this.interactions, [
          "interaction_type",
          INTERACTION_TYPES.PHONE
        ]) !== undefined
      );
    },

    hasDisplayedEmail() {
      return (
        _.find(this.interactions, [
          "interaction_type",
          INTERACTION_TYPES.MAIL
        ]) !== undefined
      );
    }
  },

  methods: {
    fullAttachmentUrl(attachment) {
      return process.env.VUE_APP_CMN_URL + "/" + attachment.document_path;
    }
  }
};
</script>
