import ProjectsApi from "@/services/api/Projects";
import AdsApi from "@/services/api/Ads";
import _ from "lodash";

const state = () => ({
  projectArray: [],
  selectedProject: null,
  needProjectDataRefresh: true
});

const getters = {
  currentProject(state) {
    return state.selectedProject;
  }
};

const actions = {
  async fetch({ commit }, { id, ...query }) {
    const project = await ProjectsApi.get(id, query);
    if (project) {
      commit("REMOVE", project.id);
      commit("ADD", project);
      commit("SET_SELECTED", project.id);
    }
    return project;
  },

  async target({ commit, state }, { id }) {
    const project = _.find(state.projectArray, { id: id });
    if (project) commit("SET_SELECTED", project.id);
    return project;
  },

  async update({ commit }, { id, ...body }) {
    let project = await ProjectsApi.update(id, body);
    commit("UPDATE", project);
    commit("SET_SELECTED", project.id);
    return project;
  },

  async fetchInteractions({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getInteractions(id, query);
    commit("interaction/INIT_LIST", data, { root: true });
    return data;
  },

  async fetchSearches({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getSearches(id, query);
    commit("search/INIT_LIST", data, { root: true });
    if (data.length == 1)
      commit("search/SET_SELECTED", data[0].id, { root: true });
    commit("user/SET_IS_BUYER", data, { root: true });
    commit("user/SET_HAS_PROPERTY_FOR_SALE", data, { root: true });
    commit("user/SET_IS_QUALIFIED", data, { root: true });
    return data;
  },

  async fetchPros({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getPros(id, query);
    commit("pro/INIT_LIST", data, { root: true });
    return data;
  },

  async fetchChats({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getChats(id, query);
    commit("chat/INIT_LIST_CHAT", data, { root: true });
    return data;
  },

  async fetchSale({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getSale(id, query);
    commit("sale/UPDATE", data, { root: true });
    return data;
  },

  async fetchProgresses({ commit }, { id, ...query }) {
    let data = await ProjectsApi.getProgress(id, query);
    commit("progress/INIT", data, { root: true });
    return data;
  },

  async fetchAds({ commit, rootGetters }, { forcePolling }) {
    let data = await AdsApi.fetch();
    console.log('forcePolling', forcePolling)
    if (rootGetters["search/hasNewActivePurchaseSearch"] || forcePolling)
      commit("ad/INIT_LIST_WITH_POLLING", data, { root: true });
    else
      commit("ad/INIT_LIST", data, { root: true });
    return data;
  }
};

const mutations = {
  INIT_LIST(state, projects) {
    state.projectArray = projects;
  },

  SET_SELECTED(state, id) {
    state.selectedProject = _.find(state.projectArray, { id: id });
  },

  NEED_PROJECT_DATA_REFRESH(state, value) {
    state.needProjectDataRefresh = value;
  },

  ADD(state, project) {
    state.projectArray.push(project);
  },

  REMOVE(state, id) {
    state.projectArray = _.find(state.projectArray, { id: id })
      ? _.reject(state.projectArray, { id: id })
      : state.projectArray;
  },

  UPDATE(state, project) {
    state.projectArray = _.map(state.interactionArray, p => {
      return p.id == project.id ? { ...project } : p;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
